<template>
  <div class="ds-wrapper relative z-10">
    <vs-card class="ds-card mb-4 md:mb-6">
      <div slot="header" class="flex flex-row justify-between">
        <h4>Request details</h4>
        <a v-if="paymentRequest.product && paymentRequest.product.statementOfAccount && paymentRequest.product.statementOfAccount.soaUrl" target="_blank" :href="paymentRequest.product.statementOfAccount.soaUrl" class="no-underline">
          <vs-icon class="material-icons-outlined">feed</vs-icon> Statement
        </a>
      </div>
      <div>
        <div class="pt-5">
          <div class="grid sm:grid-cols-2 gap-y-6 gap-x-20">
            <div>
              <div class="flex flex-col gap-y-2">
                <p class="text-ancillary text-body-dark">Request from</p>
                <p class="text-primary">{{ paymentRequest.payeeName }}</p>
                <p class="text-primary">{{ paymentRequest.payeePhone }}</p>
                <p class="text-primary">{{ paymentRequest.payeeEmail }}</p>
              </div>
            </div>
            <div>
              <div class="flex flex-col gap-y-2">
                <p class="text-ancillary text-body-dark">Reference</p>
                <p class="text-primary">{{ paymentRequest.reference }}</p>
              </div>
            </div>
            <div class="sm:mt-8">
              <p class="text-ancillary text-body">Merchant</p>
              <div class="flex justify-between items-center mt-1">
                <p class="text-primary">{{ paymentRequest.paymentPage && paymentRequest.paymentPage.pageBranding ? paymentRequest.paymentPage.pageBranding.companyName : '' }}</p>
                <vs-icon class="cursor-pointer text-xl text-body" @click="isShow = !isShow">{{isShow ? 'expand_less' : 'expand_more' }}</vs-icon>
              </div>
              <div class="flex flex-col gap-y-4 mt-4" v-if="isShow && paymentRequest.paymentPage && paymentRequest.paymentPage.pageBranding">
                <div class="flex gap-x-3 items-center" v-if="paymentRequest.paymentPage.pageBranding.companyAbn">
                  <svg width="19" height="8" viewBox="0 0 19 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.62925 5.98196H2.12725L1.71325 7.17896H0.39025L2.64925 0.887955H4.11625L6.37525 7.17896H5.04325L4.62925 5.98196ZM4.28725 4.97396L3.37825 2.34596L2.46925 4.97396H4.28725ZM10.7836 3.95695C11.1376 4.02296 11.4286 4.19995 11.6566 4.48795C11.8846 4.77595 11.9986 5.10595 11.9986 5.47795C11.9986 5.81396 11.9146 6.11096 11.7466 6.36896C11.5846 6.62096 11.3476 6.81896 11.0356 6.96296C10.7236 7.10696 10.3546 7.17896 9.92863 7.17896H7.21963V0.896955H9.81163C10.2376 0.896955 10.6036 0.965955 10.9096 1.10395C11.2216 1.24195 11.4556 1.43396 11.6116 1.67996C11.7736 1.92595 11.8546 2.20495 11.8546 2.51696C11.8546 2.88295 11.7556 3.18895 11.5576 3.43496C11.3656 3.68095 11.1076 3.85495 10.7836 3.95695ZM8.47963 3.48895H9.63163C9.93163 3.48895 10.1626 3.42295 10.3246 3.29095C10.4866 3.15295 10.5676 2.95795 10.5676 2.70595C10.5676 2.45395 10.4866 2.25895 10.3246 2.12095C10.1626 1.98295 9.93163 1.91395 9.63163 1.91395H8.47963V3.48895ZM9.74863 6.15296C10.0546 6.15296 10.2916 6.08095 10.4596 5.93695C10.6336 5.79296 10.7206 5.58896 10.7206 5.32495C10.7206 5.05495 10.6306 4.84495 10.4506 4.69495C10.2706 4.53895 10.0276 4.46095 9.72163 4.46095H8.47963V6.15296H9.74863ZM18.3758 7.17896H17.1158L14.2628 2.86795V7.17896H13.0028V0.887955H14.2628L17.1158 5.20795V0.887955H18.3758V7.17896Z" fill="#AAAAAA"/>
                  </svg>
                  <p class="text-body">{{ formatAbn(paymentRequest.paymentPage.pageBranding.companyAbn) }}</p>
                </div>
                <div class="flex items-center gap-x-3" v-if="paymentRequest.paymentPage.pageBranding.companyWeb">
                  <vs-icon class="text-lg text-neutral-500">language</vs-icon>
                  <p class="text-body">{{ paymentRequest.paymentPage.pageBranding.companyWeb }}</p>
                </div>
                <div class="flex gap-x-3 items-center" v-if="paymentRequest.paymentPage.pageBranding.companyAddress">
                  <vs-icon class="text-lg text-neutral-500 material-icons-outlined">location_on</vs-icon>
                  <p class="text-body w-3/4">{{ paymentRequest.paymentPage.pageBranding.companyAddress }}</p>
                </div>
                <div class="flex items-center gap-x-3" v-if="paymentRequest.paymentPage.pageBranding.companyPhone">
                  <vs-icon class="text-lg text-neutral-500 material-icons-outlined">call</vs-icon>
                  <p class="text-body w-3/4">{{ paymentRequest.paymentPage.pageBranding.companyPhone }}</p>
                </div>
                <div class="flex items-center gap-x-3" v-if="paymentRequest.paymentPage.pageBranding.companyEmail">
                  <vs-icon class="text-lg text-neutral-500 material-icons-outlined">mail</vs-icon>
                  <p class="text-body w-3/4">{{ paymentRequest.paymentPage.pageBranding.companyEmail }}</p>
                </div>
              </div>
            </div>
          </div>
          <hr class="my-8 line-hr" v-if="paymentRequest.files.length" />
          <div v-if="paymentRequest.files.length">
            <h5 class="text-primary mb-8 sm:mb-9">Attached files</h5>
            <div class="grid sm:grid-cols-2 gap-y-6 gap-x-20">
              <div v-for="(file, index) in paymentRequest.files" :key="index">
                <S3FileGenerator :document="file.name" :key="file.name" v-if="file.name" />
              </div>
            </div>
          </div>
          <div v-if="readyToRender && ((paymentRequest.productCustomFields && paymentRequest.productCustomFields.length > 0))">
            <hr class="my-8 line-hr" />
            <AdditionalInformation
              :customFields="paymentRequest.customFields"
              :productFields="paymentRequest.productCustomFields"
              :prId="prId"
              :objectId="paymentRequest._id"
            />
          </div>
          <div v-if="readyToRender && ((paymentRequest.customFields && paymentRequest.customFields.length > 0))">
            <hr class="my-8 line-hr" />
            <CustomFields
              :customFields="paymentRequest.customFields"
              :prId="prId"
              :objectId="paymentRequest._id"
            />
          </div>
        </div>
      </div>
    </vs-card>
  </div>
</template>

<script>
import AdditionalInformation from "./AdditionalInformation.vue";
import CustomFields from "./CustomFields.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import S3FileGenerator from "@/views/customer/components/S3FileGenerator";

export default {
  props: ["paymentRequest", "isChangeOpen", "reloadContent", "prWithPartnerDetail", "prId", "readyToRender"],
  components: {
    AdditionalInformation,
    CustomFields,
    S3FileGenerator,
    VueGoogleAutocomplete
  },
  data() {
    return {
      value1: '23/02/2023',
      value2: '23/02/2023',
      editedFieldId: null,
      isExpanded2: false,
      isShow: false,
      rawAddress: '',
      formData: {
        street_number: "",
        postcode: "",
        street_name: "",
        suburb: "",
        state: "",
        rawAddress: "",
      }
    }
  },

  methods: {
    addZeroes(num) {
      num = String(num);
      return Number(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    async setId(id) {
      this.editedFieldId = await id;
    },

    toggleEdit(id = null, action = null) {
      if (id) {
        this.setId(id).then(res => {
          if (document.getElementById('field'+id)) {
            document.getElementById('field'+id).focus();
          }
        });
      } else {
        this.editedFieldId = null;
        if (action == 'cancel') {
          this.value1 = '';
        }
      }
    },

    toggleExpand2() {
      this.isExpanded2 = !this.isExpanded2;
    },

    formatAbn(abn) {
      if (!abn) {
        return null;
      }

      return abn.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
  },

  computed: {
    partnerName() {
      if(this.paymentRequest.partner && this.paymentRequest.partner[0].companyName) {
        return this.paymentRequest.partner[0].companyName;
      }
    },
    fee() {
      if (this.paymentRequest.selectedPaymentPlan.feeModel === "MSF_INCLUDED") {
        return "0.00";
      } else {
        let feeAmount = 0;
        if (this.paymentRequest.selectedPaymentPlan && Object.keys(this.paymentRequest.selectedPaymentPlan).length > 0) {
          feeAmount = parseFloat(this.paymentRequest.selectedPaymentPlan.purchaseAmount) * (parseFloat(this.paymentRequest.selectedPaymentPlan.msf) / 100);
          if (this.paymentRequest.selectedPaymentPlan.minimumMsfFeeAmount && this.paymentRequest.selectedPaymentPlan.minimumMsfFeeAmount > feeAmount) {
            feeAmount = this.paymentRequest.selectedPaymentPlan.minimumMsfFeeAmount;
          }
        }
        return feeAmount;
      }
    },

    totalAmount() {
      return (parseFloat(this.paymentRequest.selectedPaymentPlan.purchaseAmount) + parseFloat(this.fee));
    },
  },
};
</script>
