<template>
  <div class="ds-wrapper">
    <vs-alert :active="paymentRequest.balances && paymentRequest.balances.overdueBalance > 0" color="danger" icon="error_outline" class="mb-4 single-line-alert">
      <span>{{ moneyFormat(paymentRequest.balances.overdueBalance) }} is overdue</span>
    </vs-alert>
    <vs-alert :active="showCompletedBanner" color="success" icon="check_circle_outline" class="mb-4 single-line-alert">
      <span>{{ getPlanCompletionDate }}</span>
    </vs-alert>

    <div v-if="paymentRequest && paymentRequest.paymentStatus == 'Active' && ['B2C_BNPO', 'B2C_BNPO_NC'].includes(paymentRequest.selectedPaymentPlan.currentPlan.productType)" class="rounded-t-2xl sm-rounded-t-3xl bg-container-80 p-4 sm:px-8">
      <div class="flex flex-row justify-between pb-2">
        <div>
          <p class="font-light text-body">Amount</p>
          <h3>{{ moneyFormat(paymentRequest.balances && paymentRequest.balances.balance ? paymentRequest.balances.balance : 0) }}</h3>
        </div>
        <div class="flex flex-col text-right">
          <p class="font-light text-body">Due date</p>
          <h3>{{ lastTransaction ? lastTransaction.dateFormatted : "" }}</h3>
        </div>
      </div>
      <div class="relative" ref="progressBase" v-if="showProgressBar">
        <vs-progress :height="7" :percent="spentDaysInPercentage" color="accent-300" class="mb-6"></vs-progress>
        <div class="ancillary font-light text-body progress-info" ref="progressInfo" :data-current-value="spentDaysInPercentage">{{ `${getRemainingDays} ${getRemainingDays > 1 ? 'days' : 'day'} ` }} to go</div>
      </div>
    </div>
    <div v-else-if="paymentRequest && paymentRequest.paymentStatus == 'Active'" class="rounded-t-2xl sm-rounded-t-3xl bg-container-80 p-4 sm:px-8">
      <div class="flex flex-row justify-between pb-2">
        <div>
          <p class="font-light text-body">Amount paid</p>
          <h3>{{ moneyFormat(paymentRequest.balances && paymentRequest.balances.totalPaid ? paymentRequest.balances.totalPaid : 0) }}</h3>
        </div>
        <div class="flex flex-col text-right">
          <p class="font-light text-body">Remaining balance</p>
          <h3>{{ moneyFormat(paymentRequest.balances && paymentRequest.balances.balance ? paymentRequest.balances.balance : 0) }}</h3>
        </div>
      </div>
      <div class="relative">
        <vs-progress :height="12" :percent="totalAmountPaidInPercentage" color="success-300" class="mb-2"></vs-progress>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    paymentRequest: {
      type: Object,
      default: {}
    },
  },

  data() {
    return {
      showProgressBar: true,
      showCompletedBanner: false,
    }
  },

  mounted() {
    if (this.paymentRequest.paymentStatus == "Completed") {
      this.showCompletedBanner = true;
      this.showProgressBar = false;
      return;
    }

    if (["B2C_BNPO", "B2C_BNPO_NC"].includes(this.paymentRequest.selectedPaymentPlan.currentPlan.productType)) {
      let currentValue = this.$refs.progressInfo.getAttribute("data-current-value");
      let width = this.$refs.progressInfo.clientWidth;
      let baseWidth = this.$refs.progressBase.clientWidth;
      let progressBar = currentValue/100 * baseWidth;

      if (progressBar + width/2 > baseWidth) {
        this.$refs.progressInfo.style.right = "0%";
      } else if ((progressBar - width/2) > 0) {
        this.$refs.progressInfo.style.transform = "translateX(-50%)";
        this.$refs.progressInfo.style.left = currentValue + "%";
      }
    }
  },

  methods: {

    moneyFormat(data) {
      if (!data) {
        return "$ 0.00";
      }
      return `$ ${this.currencySeparator(data)}`;
    },

    currencySeparator(data) {
      return parseFloat(data).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },

    moment(date) {
      return moment(date).format("D MMM YYYY");
    },

    calculateInPercentage(firstValue, secondValue) {
      return (firstValue / secondValue) * 100;
    },
  },

  computed: {

    getRemainingDays() {
      let remainingDays = this.lastTransaction && this.lastTransaction.date ? moment(this.lastTransaction.date).diff(moment().format("YYYY-MM-DD"), "days") : 0;

      if (remainingDays < 0) {
        this.showProgressBar = false;
      }
      
      return remainingDays > 0 ? remainingDays : 0;
    },

    spentDays() {
      return moment(new Date()).diff(this.paymentRequest.openingDate, "days");
    },

    spentDaysInPercentage() {
      return this.calculateInPercentage(this.getRemainingDays == 0 ? this.totalDays : this.spentDays, this.totalDays);
    },

    totalAmountPaidInPercentage() {
      return this.calculateInPercentage(this.paymentRequest.balances && this.paymentRequest.balances.totalPaid ? this.paymentRequest.balances.totalPaid : 0, this.paymentRequest.selectedPaymentPlan.totalCustomerPayable);
    },

    totalDays() {
      return this.lastTransaction && this.lastTransaction.date ? moment(this.lastTransaction.date).diff(moment(this.paymentRequest.openingDate).format('YYYY-MM-DD'), "days") : 0;
    },

    getPlanCompletionDate() {
      let completedDate = "";

      if (["B2C_BNPO", "B2C_BNPO_NC"].includes(this.paymentRequest.selectedPaymentPlan.currentPlan.productType)) {
        completedDate = this.moment(this.paymentRequest.closingDate);
      } else {
        const objIndex = this.paymentRequest.history.findIndex((data) => data.status == "Complete");
        if (objIndex > -1) {
          completedDate = this.moment(this.paymentRequest.history[objIndex].createdAt);
        }
      }

      return "Plan completed on " + completedDate;
    },

    lastTransaction() {
      let transaction = {};

      if (this.paymentRequest.transactions.length) {
        let transactions = this.paymentRequest.transactions.filter((item) => item.type == "Payment" && item.subType == "Regular");
        transaction = transactions.length ? transactions[transactions.length - 1] : transaction;
      }

      return transaction;
    }
  },

  watch: {
    "paymentRequest.paymentStatus": function (value) {
      if (value == "Completed") {
        this.showCompletedBanner = true;
        this.showProgressBar = false;
      }
    },
  }
}
</script>

