<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 25 24"
    :aria-labelledby="iconName"
    role="presentation"
    fill="none"
  >
    <path
      d="M7.5 11H5.5V18H7.5V11ZM13.5 11H11.5V18H13.5V11ZM22 20H3V22H22V20ZM19.5 11H17.5V18H19.5V11ZM12.5 4.26L17.71 7H7.29L12.5 4.26ZM12.5 2L3 7V9H22V7L12.5 2Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "icon",
    },
    width: {
      type: [Number, String],
      default: 25,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    color: {
      type: String,
      default: "#AAAAAA",
    },
  },
};
</script>
