<template>
  <div class="ds-wrapper">
    <div class="mb-4 md:mb-6">
      <div class="bg-container-60 py-3 px-4 md:px-8 rounded-t-2xl md-rounded-t-3xl">
        <h4>Transactions</h4>
      </div>
      <div class="grid gap-y-1 payment-list">
        <template v-for="(transaction, index) in paymentTransactions">
          <div :class="transaction.showChild ? 'bg-container-80' : 'bg-container-40'" :key="index + 0.1">
            <div :class="`py-6 px-4 md:py-8 md:px-8 ${transaction.hasChild ? 'cursor-pointer' : ''}`" @click="transaction.hasChild ? handleDisplayChild(index) : ''">
              <div class="flex justify-between">
                <div class="grid gap-y-3">
                  <p class="text-body uppercase">{{ transaction.typeText }}</p>
                  <p class="text-primary">{{ transaction.dateFormatted }}</p>
                </div>
                <div class="grid gap-y-3">
                  <div class="flex items-center justify-end gap-x-2">
                    <p class="text-primary text-lg-md">{{ transaction.amountFormatted }}</p>
                    <template v-if="transaction.hasChild" class="cursor-pointer">
                      <vs-icon v-if="!transaction.showChild" icon="expand_more" class="text-body cursor-pointer expand" />
                      <vs-icon v-else icon="expand_less" class="text-body cursor-pointer expand" />
                    </template>
                  </div>
                  <vs-chip :color="getChipColor(transaction.status)" size="x-small" variant="outlined">{{ transaction.statusText }}</vs-chip>
                </div>
              </div>
              <div v-if="transaction.showChild" class="mt-6 h-border"></div>
            </div>
            <template v-if="transaction.showChild">
              <div v-if="transaction.linkedTransactions && transaction.linkedTransactions.length" class="show-transactions grid gap-y-12 md-gap-y-14 pb-8 px-4 md:px-8">
                <template v-for="(linkedTransaction, key) in transaction.linkedTransactions">
                  <div :key="key+0.2">
                    <div class="flex justify-between">
                      <div class="grid gap-y-3 sm:gap-y-4">
                        <p class="text-body">{{ linkedTransaction.dateFormatted }}</p>
                        <div class="flex items-center gap-x-4 md:gap-x-3">
                          <img :src="linkedTransaction.icon" alt="direct-debit" />
                          <p :class="`text-${getChipColor(linkedTransaction.status)}-600 text-ancillary-caps uppercase`">{{ linkedTransaction.typeText }}</p>
                        </div>
                      </div>
                      <div class="grid gap-y-3 sm:gap-y-4">
                        <p class="text-body para-sm-light">ID: {{ linkedTransaction.reference }}</p>
                        <p :class="`text-${getChipColor(linkedTransaction.status)}-600`" class="text-right">{{ linkedTransaction.amountFormatted }}</p>
                      </div>
                    </div>
                    <div class="flex gap-x-2 mt-6" :key="key+0.3" v-if="linkedTransaction.status == 'Processing' && defaultPaymentMethod.type == 'DIRECT_DEBIT'">
                      <TipsIcon />
                      <p class="text-sm text-body">Direct debits can take 2-3 business days</p>
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import TipsIcon from "../../../../../admin/src/views/components/design-sys-components/icons/TipsIcon.vue";

const directDebitCard = require("@/assets/images/cards/directdebit.svg");
const visaCard = require("@/assets/images/cards/visa.svg");
const masterCard = require("@/assets/images/cards/mastercard.svg");
const amexCard = require("@/assets/images/cards/amex.svg");
const dinersClubCard = require("@/assets/images/cards/dinersclub.svg");
const unionpayCard = require("@/assets/images/cards/unionpay.svg");

export default {
  props:["customerPaymentMethods", "paymentRequest", "reloadContent", "transactions"],

  data() {
    return {
      directDebitCard: directDebitCard,
      visaCard: visaCard,
      masterCard: masterCard,
      amexCard: amexCard,
      dinersClubCard: dinersClubCard,
      unionpayCard: unionpayCard,
      directDebitCard: directDebitCard,
      paymentTransactions: [],
    }
  },

  components: {
    TipsIcon,
  },

  methods: {
    getChipColor(status) {
      let color = "";

      switch(status) {
        case "Successful":
          color = "success";
          break;
        case "Processing":
          color = "primary";
          break;
        case "Scheduled":
          color = "neutral";
          break;
        case "Applied":
        case "Failed":
        case "Missed":
          color = "error";
          break;
        default:
          color = "neutral";
          break;
      }

      return color;
    },

    handleDisplayChild(index) {
      for (let i = 0; i < this.paymentTransactions.length; i++) {
        if (index == i) {
          this.paymentTransactions[i].showChild = !this.paymentTransactions[i].showChild;
        } else {
          this.paymentTransactions[i].showChild = false;
        }
      }

      this.$forceUpdate();
    }
  },

  computed: {
    defaultPaymentMethod() {
      return this.customerPaymentMethods.find(item => item.default);
    },
  },

  mounted() {
    this.paymentTransactions = JSON.parse(JSON.stringify(this.transactions));
    this.paymentTransactions = this.paymentTransactions.map(item => {
      item.hasChild = item.paymentButton || (item.linkedTransactions && item.linkedTransactions.length);
      item.showChild = false;
      return item;
    });
  }
}
</script>

