<template>
  <div class="ds-wrapper mb-4 md:mb-6">
    <div v-show="showDefaultMethod" class="bg-container-60 px-4 py-4 md:px-8 rounded-2xl md-rounded-3xl" id="customerPaymentMethods">
      <h4 class="mb-6">Your payment method{{ getPaymentMethodsLength > 1 ? "s (default selected)" : ""}}</h4>
      <vs-alert v-show="getPaymentMethodsLength < 1" color="warning" icon="error_outline" class="mb-2 md:mb-4 single-line-alert">
        <p>You must add at least one payment method to your payment plan</p>
      </vs-alert>
      <div class="flex flex-col border-b-list">
        <div v-for="(paymentMethod, key) in customerPaymentMethods" :key="key" class="flex flex-row justify-between py-4">
          <div class="flex flex-row items-center" v-if="paymentMethod.type == 'CARD'">
            <vs-radio v-model="paymentMethod.default" vs-name="paymentOption" :vs-value="true" @change="selectPaymentMethod(key)"/>
            <label for="option1" class="flex flex-row items-center pointer">
              <img :src="getCardType(paymentMethod.card_type)" alt="paymentMethod.card_type" />
              <p><span class="ml-4 md:ml-6">**** {{ paymentMethod.last_four_digits }}</span><span class="ml-4 md:ml-6">{{ getExpiredYear(paymentMethod) }}</span></p>
            </label>
          </div>
          <div class="flex flex-row items-center" v-if="paymentMethod.type == 'DIRECT_DEBIT'">
            <vs-radio v-model="paymentMethod.default" vs-name="paymentOption" :vs-value="true" @change="selectPaymentMethod(key)"/>
            <label for="option1" class="flex flex-row items-center pointer">
              <img :src="directDebitCard" alt="bank" />
              <p><span class="ml-4 md:ml-6">{{ paymentMethod.accountName }}</span><span class="ml-4 md:ml-6">{{ getAccountNumber(paymentMethod.accountNumber) }}</span></p>
            </label>
          </div>
          <vs-icon icon="delete_outline" class="cursor-pointer self-center icon-action" @click="prepareDelete(key)" v-if="getPaymentMethodsLength > 1" />
        </div>
      </div>
      <hr class="mb-6" />
      <div class="text-center mb-8 pb-1"><vs-button type="flat" icon="add" class="mx-auto" @click="toggleExpand(2)" size="medium">Add payment method</vs-button></div>
    </div>
    <div v-show="showAddPaymentMethod" class="bg-container-60 rounded-2xl px-4 md:px-6 pt-3 md:pt-5 pb-5" id="addPaymentMethod">
      <PaymentCard
        :cardTitle="cardTitle"
        :showPaymentCard="showAddPaymentMethod"
        :legals="legals"
        :buttonText="buttonText"
        :showDefault="false"
        :prWithPartnerDetail="paymentRequest"
        :numberInput="cardNumberInput"
        :cvvInput="cardCvvInput"
        :requestType="'addMethod'"
        @cancelPayment="toggleExpand(2)"
        @addCustomerPayment="updatePaymentMethod"
      />
    </div>

    <vs-popup title="Delete payment method" header-icon="warning" class="close-icon-hidden" :active.sync="deleteMethodPopup">
      <p v-if="selectedPaymentMethod.type == 'CARD'">Delete {{ this.selectedPaymentMethod.card_type }} ending in **** {{ this.selectedPaymentMethod.last_four_digits }}</p>
      <p v-if="selectedPaymentMethod.type == 'DIRECT_DEBIT'">Delete bank ending in {{ getAccountNumber(selectedPaymentMethod.accountNumber) }}</p>
      <div class="popup-action-btn-container flex justify-end">
        <vs-button size="large" action-btn @click="deletePaymentMethod">Delete</vs-button>
        <vs-button @click="cancelDelete" size="large" type="flat" class="ml-6 md:ml-10">Cancel</vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
  import { mapActions, mapMutations } from "vuex";
  import { sentryLog } from "../../../helper/sentryLog";
  import PaymentCard from "../payment-request/PaymentCard.vue";

  // Credit Cards
  const visaCard = require("@/assets/images/cards/visa.svg");
  const masterCard = require("@/assets/images/cards/mastercard.svg");
  const amexCard = require("@/assets/images/cards/amex.svg");
  const dinersClubCard = require("@/assets/images/cards/dinersclub.svg");
  const unionpayCard = require("@/assets/images/cards/unionpay.svg");
  const directDebitCard = require("@/assets/images/cards/directdebit.svg");

  export default {
    props: {
      paymentRequest: { type: Object },
      legals: { type: Object },
    },

    data() {
      return {
        appUrl: process.env.VUE_APP_API_URL,
        showDefaultMethod: true,
        showSetDefaultMethod: false,
        showAddPaymentMethod: false,
        visaCard: visaCard,
        masterCard: masterCard,
        amexCard: amexCard,
        dinersClubCard: dinersClubCard,
        unionpayCard: unionpayCard,
        directDebitCard: directDebitCard,
        deleteMethodPopup: false,
        selectedIndex: null,
        selectedPaymentMethod: "",
        customerPaymentMethods: [],
        defaultpaymentMethod: "",
        cardTitle: "Default payment method",
        buttonText: "Add",
        cardNumberInput: "add_payment_number_div",
        cardCvvInput: "add_payment_cvv_div",
      }
    },

    components: { PaymentCard },

    methods: {
      ...mapActions("customer", [ "updateDefaultPaymentMethod" ,"updateCustomerPaymentMethod"]),
      ...mapMutations("customer", ["SET_CUSTOMER_PAYMENT_METHODS"]),

      getDefaultPaymentMethod() {
        if (this.paymentRequest.customerPaymentMethods && this.paymentRequest.customerPaymentMethods.length) {
          this.defaultpaymentMethod = this.paymentRequest.customerPaymentMethods.find(item => item.default);
        }
      },

      // This method handles updating the default payment method and also deleting the default payment
      saveCustomerPaymentMethod(deleted = false) {
        if (!this.customerPaymentMethods) {
          return;
        }

        const payload = {
          _id: this.paymentRequest._id,
          data: {
            customerPaymentMethods: this.customerPaymentMethods
          }
        };

        this.$vs.loading();
        this.updateDefaultPaymentMethod(payload).then(result => {
          this.$vs.loading.close();
          this.showToastMessage(`Payment method ${deleted ? "deleted" : "updated"}`, deleted ? "Payment method deleted successfully" : "Future payments will now be charged to your new payment method.", "success");
          this.toggleExpand(1);
          this.$emit("reloadContent");
        }).catch(ex => {
          this.$vs.loading.close();
          this.showToastMessage(deleted ? "Error deleting payment method" : "Error updating payment method", ex.response.data.message, "error");
        });
      },

      prepareDelete(index) {
        this.selectedPaymentMethod = this.customerPaymentMethods[index];
        this.selectedIndex = index;
        this.deleteMethodPopup = true;
      },

      cancelDelete() {
        this.selectedPaymentMethod = "";
        this.selectedIndex = null;
        this.deleteMethodPopup = false;
      },

      async deletePaymentMethod() {
        if (isNaN(this.selectedIndex)) {
          this.showToastMessage("Payment method", "Payment method failed.", "error");
          return
        }

        this.customerPaymentMethods.splice(this.selectedIndex, 1);

        if (this.selectedPaymentMethod.default) {
          if (this.customerPaymentMethods.length) {
            this.customerPaymentMethods[0].default = true;
          }
        }

        await this.setDeletedToLocalStorage(this.selectedPaymentMethod);
        this.selectedPaymentMethod = "";
        this.deleteMethodPopup = false;
        this.SET_CUSTOMER_PAYMENT_METHODS([...this.customerPaymentMethods]);
        this.saveCustomerPaymentMethod(true);
      },

      selectPaymentMethod(index) {
        this.customerPaymentMethods = this.customerPaymentMethods.map((item, key) => {
          if (index != key) {
            item.default = false;
          }

          return item;
        });

        const paymentMethod = this.customerPaymentMethods[index];

        // Update default method
        this.saveCustomerPaymentMethod();
      },

      getCardType(cardType) {
        let card = "";

        switch (cardType) {
          case "visa":
            card = this.visaCard;
            break;

          case "mastercard":
            card = this.masterCard;
            break;

          case 'amexCard':
            card = this.amexCard;
            break;

          case "unionpay":
            card = this.unionunionpayCardpay;
            break;

          default:
            card = this.visaCard;
            break;
        }
        return card;
      },

      getExpiredYear(date) {
        if (!date) {
          return null;
        }
        return `${date.month}/${date.year.substring(2, 4)}`;
      },

      toggleExpand(index = 1) {
        this.showSetDefaultMethod = !this.showSetDefaultMethod;
        this.showDefaultMethod = !this.showDefaultMethod;

        if (index == 1) {
          if (!this.showSetDefaultMethod && ((this.paymentRequest.customerPaymentMethods.length != this.customerPaymentMethods.length) || localStorage["deletedOptions"])) {
            this.$emit("reloadContent");
          }

          this.listPaymentMethods();
        } else {
          this.showAddPaymentMethod = !this.showAddPaymentMethod;

          setTimeout(() =>{
            let el;

            if (this.showAddPaymentMethod) {
              el = document.getElementById("addPaymentMethod");
            } else {
              el = document.getElementById("customerPaymentMethods");
            }

            el.scrollIntoView({ behavior: "smooth", block: "end" });
          }, 300);
        }
      },

      updatePaymentMethod(paymentData) {
        this.$vs.loading();

        this.updateCustomerPaymentMethod(paymentData).then(async(result) => {
          this.$vs.loading.close();
          this.showToastMessage("Payment method", "New payment method added", "success");
          this.customerPaymentMethods = await this.getDataFromLocalStorage(result.data.data);
          this.SET_CUSTOMER_PAYMENT_METHODS([...this.customerPaymentMethods]);
          this.toggleExpand(2);
        }).catch(ex => {
          this.$vs.loading.close();
          this.showToastMessage("Payment method", ex.response.data.message, "error");
          sentryLog(ex);
        });
      },

      listPaymentMethods() {
        this.customerPaymentMethods = [ ...this.paymentRequest.customerPaymentMethods ];
      },

      getAccountNumber(accNum) {
        accNum = accNum.trim().split(" ").join("");
        return accNum.slice(0, accNum.length - 4).replace(/./g, "*")+ accNum.slice(accNum.length - 4);
      },

      async setDeletedToLocalStorage(selectedData) {
        let localStorageData = [];
        const deletedData = selectedData.type == "CARD" ? selectedData.uuid : selectedData.accountNumber;

        if (localStorage["deletedOptions"]) {
          localStorageData = JSON.parse(localStorage.getItem("deletedOptions"));
        }

        localStorageData.push(deletedData);
        localStorage.setItem("deletedOptions", JSON.stringify(localStorageData));
      },

      async getDataFromLocalStorage(selectedData) {
        let updatedCustomerPaymentMethods = JSON.parse(JSON.stringify(selectedData));

        if (localStorage["deletedOptions"]) {
          const localStorageData = JSON.parse(localStorage.getItem("deletedOptions"));

          if (this.defaultpaymentMethod.type == "CARD") {
            updatedCustomerPaymentMethods = updatedCustomerPaymentMethods.filter(item => !localStorageData.includes(item.uuid));
          } else if (this.defaultpaymentMethod.type == "DIRECT_DEBIT") {
            updatedCustomerPaymentMethods = updatedCustomerPaymentMethods.filter(item => !localStorageData.includes(item.accountNumber));
          }
        }

        return updatedCustomerPaymentMethods;
      }
    },

    mounted() {
      this.getDefaultPaymentMethod();

      if (this.paymentRequest && this.paymentRequest.connectorDetail) {
        this.till_public_key = this.paymentRequest.connectorDetail.publicIntegration;
      }

      this.listPaymentMethods();
    },

    computed: {
      getPaymentMethodsLength() {
        return this.customerPaymentMethods.length;
      },

      displayDefaultPayment() {
        return this.customerPaymentMethods && this.customerPaymentMethods.length > 0 && this.customerPaymentMethods[0].type != "EFT";
      },
    }
  }
</script>

