<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 28 24"
    :aria-labelledby="iconName"
    role="presentation"
    fill="none"
  >
    <path d="M3.9832 17.4H2.7832V21.6H3.9832V17.4ZM7.5832 17.4H6.3832V21.6H7.5832V17.4ZM12.6832 22.8H1.2832V24H12.6832V22.8ZM11.1832 17.4H9.9832V21.6H11.1832V17.4ZM6.9832 13.356L10.1092 15H3.8572L6.9832 13.356ZM6.9832 12L1.2832 15V16.2H12.6832V15L6.9832 12Z" :fill="color"/>
    <path d="M18.0174 5.4H16.8174V9.6H18.0174V5.4ZM21.6174 5.4H20.4174V9.6H21.6174V5.4ZM26.7174 10.8H15.3174V12H26.7174V10.8ZM25.2174 5.4H24.0174V9.6H25.2174V5.4ZM21.0174 1.356L24.1434 3H17.8914L21.0174 1.356ZM21.0174 0L15.3174 3V4.2H26.7174V3L21.0174 0Z" :fill="color"/>
    <path d="M20.5161 19.3417C21.3164 18.3228 21.5579 17.0415 21.2817 15.87L20.4147 16.9738L19.606 16.3387L21.5115 13.9127L23.9375 15.8182L23.3023 16.6268L22.3319 15.8646C22.5918 17.2651 22.2744 18.7679 21.3248 19.9769C19.6766 22.0754 16.7266 22.5434 14.5259 21.1418L15.1674 20.325C16.9161 21.3781 19.2236 20.9873 20.5161 19.3417Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "icon",
    },
    width: {
      type: [Number, String],
      default: 28,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    color: {
      type: String,
      default: "#AAAAAA",
    },
  },
};
</script>
