<template>
  <div v-if="readyToRender">
    <div class="ds-wrapper md:w-8/12 md:max-w-2lg md:mx-auto mt-20 sm:mt-30">
      <p class="text-body text-sm">Payment ID</p>
      <h1 class="text-primary mb-6">{{ paymentRequestDetail.paymentRequestId }}</h1>
      <vs-alert v-if="transactionInProgress" color="success" icon="check_circle_outline" class="mb-4 single-line-alert">
        <span>Direct debit for {{ transactionInProgress.amountFormatted }} in progress<span v-if="transactionInProgress.waitingOnClearedFundsDate">, expected to complete by {{ transactionInProgress.waitingOnClearedFundsDate }}</span></span>
      </vs-alert>
      <Header
        v-if="readyToRender"
        :paymentRequest="paymentRequestDetail"
        :showChangePaymentMethodPopUp="openChangePaymentPopup"
        :reloadContent="reloadContent"
        :key="headerKey"
        :prWithPartnerDetail="prWithPartnerDetail"
        :legals="legals"
      />
      <change-payment-method v-if="readyToRender" :paymentRequest="paymentRequestDetail" @reloadContent="reloadContent" :legals="legals" />
      <Transactions v-if="readyToRender" :paymentRequest="paymentRequestDetail" :reloadContent="reloadContent" :transactions="paymentRequestDetail.transactions" :customerPaymentMethods="paymentRequestDetail.customerPaymentMethods" />
      <RequestDetails
        v-if="readyToRender"
        :paymentRequest="paymentRequestDetail"
        :isChangeOpen="openChangePaymentPopupFromPayment"
        :reloadContent="reloadContent"
        :prWithPartnerDetail="prWithPartnerDetail"
        :prId="prId"
        :readyToRender="readyToRender"
      />
      <AgreementSummary v-if="readyToRender" :paymentRequest="paymentRequestDetail" :privacy="privacy" />
      <Topup
        v-if="readyToRender && paymentRequestDetail && paymentRequestDetail.topups && paymentRequestDetail.topups.length > 0"
        :payment-request-detail="paymentRequestDetail"
        :top-ups="paymentRequestDetail.topups"
        :product="paymentRequestDetail.product"
        :legals="legals"
        :transactions="paymentRequestDetail.allTransactions"
        :scroll-to-topup-id="scrollToTopup"
        @reloadContent="reloadContent"
      />
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

import AdditionalInformation from "./AdditionalInformation.vue";
import AgreementSummary from "../components/AgreementSummary.vue";
import ChangePaymentMethod from "../components/ChangePaymentMethod.vue";
import Header from "./Header.vue";
import RequestDetails from "./RequestDetails.vue";
import Transactions from "./Transactions.vue";
import Topup from "./Topup.vue";

export default {
  components: {
    AdditionalInformation,
    AgreementSummary,
    ChangePaymentMethod,
    Header,
    RequestDetails,
    Topup,
    Transactions,
  },

  data() {
    return {
      headerKey: 1,
      paymentRequestDetail: "",
      currentPlan:{},
      readyToRender: false,
      openChangePaymentPopupFromPayment: false,
      prId: "",
      prWithPartnerDetail: "",
      legals: {},
      privacy: "",
      scrollToTopup: ""
    };
  },

  methods: {
    ...mapActions("customer", ["paymentRequestDetailByPRID"]),
    ...mapActions("card", ["getProductCards"]),
    ...mapActions("customer", ["fetchTermsAndConditions"]),

    reloadContent(topupId) {
      this.readyToRender = false;
      this.headerKey++;
      this.fetchDetail(`PR-${this.$route.params.prId}`);

      // if topup is paid/added to agreement, scroll to actioned topup view
      if (topupId) {
        this.scrollToTopup = topupId;
      }
    },

    openChangePaymentPopup(isOpen) {
      this.openChangePaymentPopupFromPayment = true;
    },

    async fetchDetail(id) {
      this.$vs.loading();
      await this.paymentRequestDetailByPRID(id).then(async (result) => {
        this.paymentRequestDetail = result.data.data;
        this.currentPlan = this.paymentRequestDetail.selectedPaymentPlan;
        this.privacy = this.paymentRequestDetail.privacy;
        this.$vs.loading.close();
        localStorage.removeItem("deletedOptions");
        await this.getLegals();

        this.readyToRender = (this.paymentRequestDetail.selectedPaymentPlan.currentPlan.productConfigType == "THREE_PARTY");
      }).catch((ex) => {
        this.$vs.loading.close();
      });
    },

    async getLegals() {
      await this.fetchTermsAndConditions().then(res => {
        this.legals = res.data.data;
      });
    },
  },

  async mounted() {
    if (!this.accessToken) {
      this.$router.push({ name: "Authenticate" });
      return;
    }

    this.prId = `PR-${this.$route.params.prId}`;
    await this.fetchDetail(this.prId);

    //TODO: NEED TO UNCOMMENT AFTER 1.24 IS RELEASED
    // if (localStorage.getItem("chatbotHash")) {
    //   window.intercomSettings = {
    //     api_base: "https://api-iam.intercom.io",
    //     app_id: "k4ih4toa",
    //     user_id: this.paymentRequestDetail._id, // a UUID for your user
    //     email: this.paymentRequestDetail.payeeEmail || "", // a UUID for your user
    //     user_hash: localStorage.getItem("chatbotHash") // an Identity Verification user hash for your user
    //   };

    //   let chabotScript = document.createElement("script");
    //   const textContent = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/k4ih4toa';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})()`;
    //   chabotScript.textContent = textContent;

    //   document.head.appendChild(chabotScript);
    // }
  },

  computed:{
    accessToken() {
      return localStorage.getItem("accessToken");
    },

    transactionInProgress() {
      let transaction = null;

      if (this.paymentRequestDetail.transactions && this.paymentRequestDetail.transactions.length) {
        transaction = this.paymentRequestDetail.transactions.find((item) => item.type == "Payment" && ["Processing", "Queued"].includes(item.status));
      }

      return transaction;
    }
  },
};
</script>

