<template>
  <svg
    width="15"
    height="15"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2936_2827)">
      <path
        d="M3.17957 11.0024H5.17957C5.17957 11.5524 4.72957 12.0024 4.17957 12.0024C3.62957 12.0024 3.17957 11.5524 3.17957 11.0024ZM2.17957 10.5024H6.17957V9.50244H2.17957V10.5024ZM7.92957 5.75244C7.92957 7.66244 6.59957 8.68244 6.04457 9.00244H2.31457C1.75957 8.68244 0.429565 7.66244 0.429565 5.75244C0.429565 3.68244 2.10957 2.00244 4.17957 2.00244C6.24957 2.00244 7.92957 3.68244 7.92957 5.75244ZM6.92957 5.75244C6.92957 4.23744 5.69457 3.00244 4.17957 3.00244C2.66457 3.00244 1.42957 4.23744 1.42957 5.75244C1.42957 6.98744 2.17457 7.69744 2.60457 8.00244H5.75457C6.18457 7.69744 6.92957 6.98744 6.92957 5.75244ZM10.3646 4.68744L9.67957 5.00244L10.3646 5.31744L10.6796 6.00244L10.9946 5.31744L11.6796 5.00244L10.9946 4.68744L10.6796 4.00244L10.3646 4.68744ZM9.17957 4.00244L9.64957 2.97244L10.6796 2.50244L9.64957 2.03244L9.17957 1.00244L8.70957 2.03244L7.67957 2.50244L8.70957 2.97244L9.17957 4.00244Z" fill="#AAAAAA"
      />
    </g>
    <defs>
      <clipPath id="clip0_2936_2827">
        <rect width="12" height="12" fill="white" transform="translate(0.0545654 0.502441)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>

export default ({

})
</script>
