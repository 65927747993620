<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 25 24"
    :aria-labelledby="iconName"
    role="presentation"
    fill="none"
  >
    <path
      d="M20.5 4H4.5C3.39 4 2.51 4.89 2.51 6L2.5 18C2.5 19.11 3.39 20 4.5 20H20.5C21.61 20 22.5 19.11 22.5 18V6C22.5 4.89 21.61 4 20.5 4ZM20.5 18H4.5V12H20.5V18ZM20.5 8H4.5V6H20.5V8Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "icon",
    },
    width: {
      type: [Number, String],
      default: 25,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    color: {
      type: String,
      default: "#AAAAAA",
    },
  },
};
</script>
