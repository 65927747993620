<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 13 13"
    :aria-labelledby="iconName"
    role="presentation"
    fill="none"
  >
    <rect x="1" y="0.790833" width="11" height="11" rx="5.5" :fill="color" />
      <path d="M6.79131 3.65083C7.33531 3.65083 7.76998 3.80017 8.09531 4.09883C8.42598 4.3975 8.59131 4.8055 8.59131 5.32283C8.59131 5.8615 8.42065 6.26683 8.07931 6.53883C7.73798 6.81083 7.28465 6.94683 6.71931 6.94683L6.68731 7.57883H5.89531L5.85531 6.32283H6.11931C6.63665 6.32283 7.03131 6.2535 7.30331 6.11483C7.58065 5.97617 7.71931 5.71217 7.71931 5.32283C7.71931 5.04017 7.63665 4.81883 7.47131 4.65883C7.31131 4.49883 7.08731 4.41883 6.79931 4.41883C6.51131 4.41883 6.28465 4.49617 6.11931 4.65083C5.95398 4.8055 5.87131 5.0215 5.87131 5.29883H5.01531C5.01531 4.97883 5.08731 4.6935 5.23131 4.44283C5.37531 4.19217 5.58065 3.9975 5.84731 3.85883C6.11931 3.72017 6.43398 3.65083 6.79131 3.65083ZM6.27931 9.34683C6.11398 9.34683 5.97531 9.29083 5.86331 9.17883C5.75131 9.06683 5.69531 8.92817 5.69531 8.76283C5.69531 8.5975 5.75131 8.45883 5.86331 8.34683C5.97531 8.23483 6.11398 8.17883 6.27931 8.17883C6.43931 8.17883 6.57531 8.23483 6.68731 8.34683C6.79931 8.45883 6.85531 8.5975 6.85531 8.76283C6.85531 8.92817 6.79931 9.06683 6.68731 9.17883C6.57531 9.29083 6.43931 9.34683 6.27931 9.34683Z" fill="#707070"/>
    <rect x="1" y="0.790833" width="11" height="11" rx="5.5" :stroke="stroke"/>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "icon",
    },
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    color: {
      type: String,
      default: "#E7E7E7",
    },
    stroke: {
      type: String,
      default: "#C4C4C4",
    }
  },
};
</script>
