<template>
  <div class="ds-wrapper">
    <vs-card class="ds-card mb-4 md:mb-6">
      <div slot="header">
        <h4>Original agreement as at {{ momentDate(paymentRequest.openingDate) }}</h4>
      </div>

      <div v-if="paymentRequest.selectedPaymentPlan.currentPlan.productType == 'B2C_BNPL'" class="pt-3 sm:pt-6 pb-4">
        <div class="pb-8 text-italics">
          <em>The details below show your original agreement terms - amounts and dates may be updated elsewhere on this page, reflecting changes to your account over time.
            Refer to the top of screen for up-to-date balance and payment dates.</em>
        </div>
        <div class="pb-6">
          <p><span class="text-md">Start date:</span> <span class="font-light">{{ momentDate(paymentRequest.openingDate) }}</span></p>
        </div>
        <div class="grid grid-cols-2 pb-2">
          <div><h5>Payment schedule</h5></div>
          <div class="text-right"><h5>Amount</h5></div>
        </div>
        <hr class="line-hr" />
        <div class="grid grid-cols-2 gap-2 pt-4 pb-6">
          <template v-for="(schedule, indextr) in planSchedule">
            <div :key="indextr">
              <p>{{ momentDate(schedule.date) }}</p>
            </div>
            <div :key="indextr + 0.1" class="text-right">
              <p>$ {{ addZeroes(schedule.amount) }}</p>
            </div>
          </template>
        </div>
        <hr class="line-hr" />
        <div class="grid grid-cols-2 pb-4 sm:pb-6 pt-4 sm:pt-6">
          <div>
            <h3>Total</h3>
          </div>
          <div class="text-right">
            <h3>$ {{ addZeroes(total ? total : 0) }}</h3>
          </div>
        </div>
        <p class="text-ancillary mb-6" v-if="paymentRequest.selectedPaymentPlan && paymentRequest.selectedPaymentPlan.currentPlan.feeModel == 'MSF_ADDED'">Your payment includes a {{ `${paymentRequest.selectedPaymentPlan.msf}%` }} service fee.</p>
      </div>

      <div v-else class="pt-3 sm:pt-6 pb-4">
        <div class="pb-8 text-italics">
          <em>The details below show your original agreement terms - amounts and dates may be updated elsewhere on this page, reflecting changes to your account over time.
            Refer to the top of screen for up-to-date balance and payment dates.</em>
        </div>
        <div class="pb-6">
          <p><span class="text-md">Start date:</span> <span class="font-light">{{ momentDate(paymentRequest.openingDate) }}</span></p>
        </div>
        <p class="para-def-light-anc mb-2 text-left">Our share of the proceeds will be deducted on <br class="sm:hidden"/> {{ momentDate(planSchedule[0].date) }}</p>
        <div class="grid grid-cols-2 gap-2 pt-4 pb-6">
          <div><p>Purchase amount</p></div>
          <template v-if="paymentRequest.selectedPaymentPlan.feeModel === 'MSF_ADDED'">
            <div class="text-right"><p>$ {{ addZeroes(disbursement) }}</p></div>
            <div><p>Service fee</p></div>
            <div class="text-right"><p>$ {{ addZeroes(merchantServiceFee) }}</p></div>
          </template>
          <template v-else>
            <div class="text-right"><p>$ {{ addZeroes(total) }}</p></div>
          </template>
        </div>
        <hr class="line-hr" />
        <div class="grid grid-cols-2 pb-4 sm:pb-6 pt-4 sm:pt-6">
          <div>
            <h3>Total</h3>
          </div>
          <div class="text-right">
          <h3>$ {{ addZeroes(total) }}</h3>
          </div>
        </div>
        <p v-if="paymentRequest.selectedPaymentPlan.summaryConfigurableFeeText">{{ paymentRequest.selectedPaymentPlan.summaryConfigurableFeeText }}</p>
      </div>

      <div class="grid grid-cols-1 gap-y-2 sm:gap-y-4">
        <p><a :href="termsUrl" target="_blank">Terms & Conditions</a></p>
        <p><a :href="policyUrl" target="_blank">Privacy policy</a></p>
        <p v-if="paymentTermsUrl"><a :href="paymentTermsUrl" target="_blank">Payment terms and conditions</a></p>
      </div>
    </vs-card>
  </div>
</template>

<script>
import _ from 'lodash';
import momentTz from "moment-timezone";

export default {
  props: ["paymentRequest", "privacy"],

  data() {
    return {
      appUrl: process.env.VUE_APP_API_URL,
      termsUrl:"",
      policyUrl: "",
      total: "",
      disbursement: "",
      merchantServiceFee: "",
      planSchedule: [],
      paymentTermsUrl: null
    }
  },

  methods:{
    momentDate(date) {
      return this.customDateFormat(date, "D MMM YYYY");
    },

    customDateFormat(date, dateFormat) {
      return momentTz(date).tz("Australia/Sydney").format(dateFormat);
    },

    addZeroes(num) {
      num = String(num);
      return Number(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },

  created() {
    const customerTerms = _.sortBy(this.paymentRequest.selectedPaymentPlan.currentPlan.legals.filter(legal => legal.user === "CUSTOMER"),["createdAt"]).reverse();
    this.policyUrl = `${this.appUrl}pages/terms-and-conditions/${this.privacy}?type=DASHBOARD&fullDocument=true`;
    this.termsUrl = `${this.appUrl}pages/terms-and-conditions/${customerTerms[0]._id}?type=PAY_LATER_LEGAL&fullDocument=true`;

    if (this.paymentRequest.paymentMethodTerms) {
      this.paymentTermsUrl = `${this.appUrl}pages/terms-and-conditions/${this.paymentRequest.paymentMethodTerms._id}?type=${this.paymentRequest.paymentMethodTerms.nature}&fullDocument=true&mode=pay-later`;
    }

    this.total = this.paymentRequest.totalPayable;
    this.disbursement = this.paymentRequest.disbursementAmount;
    this.merchantServiceFee = this.paymentRequest.msfAmount;

    if (this.paymentRequest && this.paymentRequest.contractualSchedule && this.paymentRequest.contractualSchedule.length) {
      this.planSchedule = this.paymentRequest.contractualSchedule.filter(item => item.type == "Payment");
    }
  }
};
</script>
