<template>
  <div class="ds-wrapper">
    <div class="grid sm:grid-cols-2 gap-y-6 gap-x-20" v-if="localData.length > 0">
      <div v-for="(field, key) in localData" :key="key">
        <div :class="field.isOpen ? field.type == 'file' ? 'editable-field' : 'editable-field editable' : 'editable-field'">
          <label class="text-body input-label" v-if="!field.error">{{ field.label }}</label>
          <template v-if="field.editableByCustomer === true && field.isOpen">
            <template  v-if="field.type === 'currency'">
              <div class="vs-input">
                <money
                  :name="`${field.label+key}`"
                  :data-vv-as="`${field.label}`"
                  v-model="field.value"
                  class="hasUnit hasUnitLeft w-full vs-input--input vs-con-input"
                  v-bind="moneyMask"
                />
              </div>
            </template>
            <div v-else-if="field.type === 'date'" class="input-datepicker" :class="field.error ? 'hasError' : ''">
              <label class="text-body input-label" v-if="field.error">{{ field.label }}</label>
              <date-picker
                v-model="field.value"
                format="DD/MM/YYYY"
                type="date"
                :name="`cfdate${key}`"
                placeholder="DD/MM/YYYY - Required"
                input-class="datepicker-input w-full"
                lang="jp"
                v-mask="'##/##/####'"
                @blur="handleDateValidation(key, field.value)"
                @change="handleDateValidation(key, field.value)"
                data-vv-as="date"
                @keyup.native="handleDateChange"
              ></date-picker>
              <span class="span-text-validation-danger" v-if="field.error"><span class="span-text-validation">{{ field.errorMessage }}</span></span>
            </div>
            <vs-select v-else-if="field.type === 'dropdown'" v-model="field.value" class="payment-no-label-select no-label-select rounded-none">
              <vs-select-item :key="index" :value="item" :text="item"  v-for="(item, index) in field.dropdownSelectFields" />
            </vs-select>
            <div class="input-file" v-else-if="field.type === 'file'">
              <input
                v-if="!field.file"
                :ref="`uploadForImgInput${key}`"
                type="file"
                class="hidden"
                name="file"
                @change="updateCurrImg($event, key)"
                accept=".pdf"
              />
              <vs-button v-if="!field.file" type="flat" icon="icon-upload"  @click="uploadImgInput(key)" icon-pack="feather" icon-size="small">Upload File</vs-button>
              <span v-if="field.file">
                <div class="input-file-name mt-4">
                  <span class="file-name"><a> {{ field.file.name }}</a></span>
                  <vs-button color="" @click="deleteFile(key)" variant="icon-button" icon="delete_outline"></vs-button>
                </div>
              </span>
            </div>
            <template v-else-if="field.type === 'address'">
              <div class="grid gap-y-6">
                <div>
                  <div v-if="!field.addressManual" class="custom-input-field">
                    <vue-google-autocomplete
                      ref="address"
                      :id="field._id"
                      :value="formatAddress(field.value)"
                      v-on:placechanged="getAddressForField"
                      classname="w-full custom-w"
                      :country="['au']"
                      placeholder="Start typing your address"
                    ></vue-google-autocomplete>
                  </div>
                  <vs-input v-if="field.addressManual" placeholder="Enter address" v-model="addressFieldArray[field._id].street_name" />
                  <vs-button v-if="field.addressManual" type="flat" class="mt-2" @click="changeAddressInputMode(key, false)" size="small">Back to auto-complete</vs-button>
                  <vs-button v-if="!field.addressManual" type="flat" class="mt-2" @click="changeAddressInputMode(key, true)" size="small">Enter manually</vs-button>
                </div>
                <div v-if="field.addressManual">
                  <div class="grid gap-y-6">
                    <div>
                      <vs-input label="Apt, Suite, Unit, Building, Floor" v-model="addressFieldArray[field._id].unit_number" />
                    </div>
                    <div>
                      <vs-input label="Suburb" v-model="addressFieldArray[field._id].suburb" />
                    </div>
                    <div class="flex flex-row gap-x-6">
                      <div class="w-1/2">
                        <vs-input label="State" v-model="addressFieldArray[field._id].state" />
                      </div>
                      <div class="w-1/2">
                        <label for="" class="vs-input--label">Postcode</label>
                        <the-mask
                            class="w-full custom-w vs-input--input"
                            type="text"
                            masked
                            :mask="['####']"
                            v-model="productAddressFieldArray[productField._id].postcode"
                            :name="`postcode[${index}]`"
                            data-vv-as="postcode"
                          />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <vs-input v-else class="input-field" :type="field.type" :placeholder="field.value" v-model="field.value" :id="'field'+key" />
            <div class="flex flex-row justify-end gap-x-6 mt-4">
              <vs-button color="primary" size="small" :disabled="!field.value || field.error" @click.prevent="saveField(key)">Save</vs-button>
              <vs-button type="flat" @click.prevent="cancelEditMode(key)" size="small">Cancel</vs-button>
            </div>
          </template>
          <template v-else>
            <p class="text-md text-primary hasUnit hasUnitLeft currency-val" v-if="field.type === 'currency'">$ {{ field.value }}</p>
            <p class="text-md text-primary" v-else-if="field.type === 'address'">{{ formatAddress(field.value) }}</p>
            <p v-else-if="field.type== 'file'">
              <S3FileGenerator :document="field.value" :key="field.value" v-if="field.value" />
            </p>
            <vs-input v-else-if="field.type == 'date'"  readonly="true" :value="formatDate(field.value)" />
            <vs-input v-else  readonly="true" :value="field.value" />
            <vs-icon type="flat" class="icon-gray edit-icon material-icons-outlined" icon="edit" icon-size="small" @click.prevent="openEditMode(key, field.value, field.type)" />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import { mapActions } from "vuex";
import { mask, TheMask } from "vue-the-mask";
import { Money } from "v-money";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import S3FileGenerator from "@/views/customer/components/S3FileGenerator";

export default {
components: {
  DatePicker,
  TheMask,
  Money,
  VueGoogleAutocomplete,
  S3FileGenerator
},
directives: {
  mask
},

props: ["customFields", "prId", "objectId"],
data() {
  return {
    localData: [],
    addressFieldArray: [],
    moneyMask: {
      thousands: ",",
      prefix: "$ ",
      suffix: "",
      precision: 2,
      masked: false,
    },
    moneyMaskNumber: {
      decimal: "",
      thousands: "",
      prefix: "",
      suffix: "",
      precision: 0,
      masked: false,
    },
    addressField: {
      rawAddress: "",
      index: "",
      suburb: "",
      state: "",
      unit_number: "",
      street_number: "",
      street_name: "",
      street_type: "",
      postcode: ""
    },
  }
},
methods: {
  ...mapActions("paymentRequest", ["saveCustomFieldInPr"]),

  moment(date) {
    return moment(date).format("DD-MMM-YYYY");
  },

  formatAddress(val) {
    if (!val) {
      return val;
    }
    let obj = JSON.parse(val);
    let address = "";

    if (obj.unit_number && Object.keys(obj.unit_number).length > 0) {
      address = obj.unit_number + ", ";
    }

    if (obj.street_name && Object.keys(obj.street_name).length > 0) {
      address = address + obj.street_name + ", ";
    } else if (obj.street && Object.keys(obj.street).length > 0) {
      address = address + obj.street + ", ";
    }

    if (obj.suburb && Object.keys(obj.suburb).length > 0) {
      address = address + obj.suburb + " ";
    }

    if (obj.state && Object.keys(obj.state).length > 0) {
      address = address + obj.state;
      if (Object.keys(obj.postcode).length > 0) {
        address = address + " " + obj.postcode + ", ";
      } else {
        address = address + ",";
      }
    }

    if (obj.country && Object.keys(obj.country).length > 0) {
      address = address + obj.country;
    } else {
      address = address + "Australia";
    }
    return address;
  },

  showField(f) {
    if (f.displayToCustomer) {
      return f.displayToCustomer;
    }
  },

  openEditMode(key, value) {
    for (let i = 0; i < this.localData.length; i++) {
      if (i == key) {
        this.localData[i].isOpen = !this.localData[i].isOpen;
      } else {
        this.localData[i].isOpen = false;
      }
    }

    this.localData[key].oldValue = value;
  },

  changeAddressInputMode(key, value) {
    this.localData[key].addressManual = value;
  },

  cancelEditMode(key) {
    this.localData[key].isOpen = false;
    this.localData[key].value = this.localData[key].oldValue;
    this.localData[key].error = false;
  },

  updateCurrImg(input, key) {
    this.localData[key].file = input.target.files[0];
    this.localData[key].value = input.target.files[0].name;
  },

  uploadImgInput(key) {
    this.$refs[`uploadForImgInput${key}`][0].click();
  },

  addZeroes(num) {
    num = String(num);
    return Number(num).toLocaleString(undefined, {
      minimumFractionDigits: 2,
    });
  },

  async saveField(key) {
    const saveData = this.localData[key];
    saveData.paymentRequestId = this.prId;
    saveData.objectId = this.objectId;
    if (saveData.type === "address") {
      saveData.value = JSON.stringify(this.addressFieldArray[saveData._id]);
    }
    const fd = new FormData();
    fd.append("file", saveData.file);
    fd.append("form", JSON.stringify(saveData));

    const obj = {
      data: fd,
      config: {
        header: {
          "Content-Type": "multipart/form-data",
        },
      },
    };
    await this.saveCustomFieldInPr(obj).then((response) => {
      const resultData = response.data.data;
      this.getLocalData(resultData);
    }).catch ((ex) => {
      this.showToastMessage(ex.response.data.title, ex.response.data.message, "error");
    });
  },

  getAddressForField: function (addressData, placeResultData, _id) {
    this.addressData = addressData;

    if (this.addressData.street_number) {
      this.addressFieldArray[_id].street_number = this.addressData.street_number;
    }

    if (this.addressData.postal_code) {
      this.addressFieldArray[_id].postcode = this.addressData.postal_code;
    }

    if (placeResultData.vicinity) {
      this.addressFieldArray[_id].suburb = placeResultData.vicinity;
    }

    if (this.addressData.administrative_area_level_1) {
      this.addressFieldArray[_id].state = this.addressData.administrative_area_level_1;
    }

    if (addressData.route) {
      let street_address = "";
      for (const address of placeResultData.address_components) {
        if (address.types.includes("street_number")) {
          street_address += address.short_name + " ";
        }

        if (address.types.includes("route")) {
          street_address += address.long_name;
        }
      }
      this.addressFieldArray[_id].street_name = street_address;
    }
  },

  getLocalData(customFields) {
    this.localData = customFields.map((item) => ({
      ...item,
      isOpen: false,
      oldValue: "",
      file: "",
      addressManual: false,
      addressAutoComplete: false
    })).filter((customField) =>  customField.displayToCustomer || customField.editableByCustomer);

    this.localData.forEach((item) => {
      if (item.type == "currency") {
        item.value = item.value.replace("$ ", "");
        item.value = item.value.replace(",", "");
        item.value = this.addZeroes(item.value);
      }
    });
  },

  deleteFile(key) {
    this.localData[key].value = "";
    this.localData[key].file = "";
  },

  handleDateValidation (key, value) {
    let error = false;
    let errorMessage = "";
    const showDatePickerValid = this.handleDatePickerFormat(value);

    if (!value) {
      error = true;
      errorMessage = "Date is required";
    } else if (!showDatePickerValid) {
      error = true;
      errorMessage = "Invalid date";
    }

    this.localData[key].error = error;
    this.localData[key].errorMessage = errorMessage;
  },
},
created() {
  this.getLocalData(this.customFields);

  for (let i = 0; i < this.localData.length; i++) {
    if (this.localData[i].type === "address") {
      this.addressField = JSON.parse(this.localData[i].value);
      this.addressField.rawAddress = `${this.addressField.street_name},${this.addressField.suburb} ${this.addressField.state}, Australia`;
      this.addressFieldArray[this.localData[i]._id] = this.addressField;
    }
  }
}
};
</script>