<template>
  <div>
    <summary-card :paymentRequest="paymentRequest"></summary-card>
    <MakePayment
      :showPayment="showMakePayment"
      :prOId="paymentRequest._id"
      :isChangeMethodOpen="showChangePaymentMethodPopUp"
      :reloadPageContent="reloadPageContent"
      :prWithPartnerDetail="paymentRequest"
      :paymentRequestDetails="prWithPartnerDetail.paymentDetails"
      :legals="legals"
    />
  </div>
</template>

<script>
import MakePayment from "./MakePayment.vue"
import SummaryCard from "./../components/SummaryCard.vue";

export default {
  components: { MakePayment, SummaryCard },
  props: ["paymentRequest", "legals", "prWithPartnerDetail", "reloadContent", "showChangePaymentMethodPopUp"],

  data() {
    return {
      payLaterSchedule: {},
      showPayLaterPopUp: false,
    }
  },

  methods: {

    reloadPageContent() {
      this.reloadContent();
    },

  },
  computed: {
    showMakePayment() {

      if (this.paymentRequest.paymentStatus === 'Completed') {
        return false;
      }

      if (this.paymentRequest.remainingAmount < 0.01) {
        return false;
      }

      let numberOfPayments = this.paymentRequest.selectedPaymentPlan.numberOfPayments;

      if (!numberOfPayments) {
        return true;
      }

      if ((parseInt(this.paymentRequest.totalNumberOfPayments)) >= (this.paymentRequest.selectedPaymentPlan.currentPlan.productType === 'B2C_BNPL' ? parseInt(numberOfPayments) : 1) ){
        return false;
      }

      return true;
    },
  }
};
</script>
