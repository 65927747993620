<template>
    <div class="ds-wrapper">
      <div class="grid sm:grid-cols-2 gap-y-6 gap-x-20" v-if="localProductData.length > 0">
        <div v-for="(productField, key) in localProductData" :key="key">
          <div :class="productField.isOpen ? productField.type == 'file' ? 'editable-field' : 'editable-field editable' :'editable-field'">
            <label class="text-body input-label" v-if="!productField.error">{{ productField.label }}</label>
            <template v-if="productField.editableByCustomerAfter === true && productField.isOpen">
              <template  v-if="productField.type === 'currency'">
                <div class="vs-input">
                  <money
                    :name="`${productField.label+key}`"
                    :data-vv-as="`${productField.label}`"
                    v-model="productField.value"
                    class="hasUnit hasUnitLeft w-full vs-input--input vs-con-input"
                    v-bind="moneyMask"
                  />
                </div>
              </template>
              <div v-else-if="productField.type === 'date'" class="input-datepicker" :class="productField.error ? 'hasError' : ''">
                <label class="text-body input-label" v-if="productField.error">{{ productField.label }}</label>
                <date-picker
                  v-model="productField.value"
                  format="DD/MM/YYYY"
                  type="date"
                  :name="`${productField.label+key}`"
                  placeholder="DD/MM/YYYY - Required"
                  input-class="datepicker-input w-full"
                  lang="jp"
                  v-mask="'##/##/####'"
                  data-vv-as="date"
                  @blur="handleDateValidation(key, productField.value)"
                  @change="handleDateValidation(key, productField.value)"
                  @keyup.native="handleDateChange"
                ></date-picker>
                <span class="span-text-validation-danger" v-if="productField.error"><span class="span-text-validation">{{ productField.errorMessage }} </span></span>
              </div>
              <vs-select v-else-if="productField.type === 'dropdown'" :name="`${productField.label+key}`" placeholder="Required" v-model="productField.value" class="payment-no-label-select no-label-select rounded-none">
                <vs-select-item :key="index" :value="item" :text="item"  v-for="(item, index) in productField.dropdownSelectFields" />
              </vs-select>
              <div class="input-file" v-else-if="productField.type === 'file'">
                <input
                  v-if="!productField.file"
                  :ref="`uploadForImgInput${key}`"
                  type="file"
                  class="hidden"
                  :name="`${productField.label+key}`"
                  @change="updateCurrImg($event, key)"
                  accept=".pdf"
                />
                <vs-button v-if="!productField.file" type="flat" icon="icon-upload"  @click="uploadImgInput(key)" icon-pack="feather" icon-size="small">Upload File</vs-button>
                <span v-if="productField.file">
                  <div class="input-file-name mt-1">
                    <span class="file-name"><a> {{ productField.file.name }}</a></span>
                    <vs-button color="" @click="deleteFile(key)" variant="icon-button" icon="delete_outline"></vs-button>
                  </div>
                </span>
              </div>
              <template v-else-if="productField.type === 'address'">
                <div class="grid gap-y-6">
                  <div>
                    <div v-if="!productField.addressManual" class="custom-input-field">
                      <vue-google-autocomplete
                        ref="address"
                        :id="productField._id"
                        :value="formatAddress(productField.value)"
                        v-on:placechanged="getAddressForProductField"
                        classname="w-full custom-w"
                        :country="['au']"
                        placeholder="Start typing your address"
                      ></vue-google-autocomplete>
                    </div>
                    <vs-input v-if="productField.addressManual" placeholder="Enter address" v-model="productAddressFieldArray[productField._id].street_name" />
                    <vs-button v-if="productField.addressManual" type="flat" class="mt-2" @click="changeAddressInputMode(key, false)" size="small">Back to auto-complete</vs-button>
                    <vs-button v-if="!productField.addressManual" type="flat" class="mt-2" @click="changeAddressInputMode(key, true)" size="small">Enter manually</vs-button>
                  </div>
                  <div v-if="productField.addressManual">
                    <div class="grid gap-y-6">
                      <div>
                        <vs-input label="Apt, Suite, Unit, Building, Floor" type="text" v-model="productAddressFieldArray[productField._id].unit_number" />
                      </div>
                      <div>
                        <vs-input label="Suburb" type="text" v-model="productAddressFieldArray[productField._id].suburb" />
                      </div>
                      <div class="flex flex-row gap-x-6">
                        <div class="w-1/2">
                          <vs-input label="State" type="text" v-model="productAddressFieldArray[productField._id].state" />
                        </div>
                        <div class="w-1/2 vs-input">
                          <label for="" class="vs-input--label">Postcode</label>
                          <the-mask
                            class="w-full custom-w vs-input--input vs-con-input postcode"
                            type="text"
                            masked
                            :mask="['####']"
                            v-model="productAddressFieldArray[productField._id].postcode"
                            :name="`postcode[${index}]`"
                            data-vv-as="postcode"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <vs-input v-else-if="productField.type === 'number'" class="input-field numeric-input" type="number" :placeholder="productField.value" v-model="productField.value" :id="'field'+key" @keypress="handleNumericValue($event)" />
              <vs-input v-else class="input-field" :type="productField.type" :placeholder="productField.value" v-model="productField.value" :id="'field'+key" />
              <div class="flex flex-row justify-end gap-x-6 mt-4">
                <vs-button color="primary" :disabled="!productField.value || productField.error" size="small" @click.prevent="saveProductField(key)">Save</vs-button>
                <vs-button type="flat" @click.prevent="cancelProductEditMode(key)" size="small">Cancel</vs-button>
              </div>
            </template>
            <template v-else>
              <p class="text-md text-primary hasUnit hasUnitLeft currency-val" v-if="productField.type === 'currency'">$ {{ productField.value }}</p>
              <p class="text-md text-primary pr-6" v-else-if="productField.type === 'address'">{{ formatAddress(productField.value) }}</p>
              <p v-else-if="productField.type== 'file'">
                <S3FileGenerator :document="productField.value" :key="productField.value" v-if="productField.value" />
              </p>
              <vs-input v-else-if="productField.type == 'date'"  readonly="true" :value="formatDate(productField.value)" />
              <vs-input v-else  readonly="true" :value="productField.value" />
              <vs-icon type="flat" class="icon-gray edit-icon material-icons-outlined" icon="edit" icon-size="small" v-if="productField.editableByCustomerAfter" @click.prevent="openProductEditMode(key, productField.value, productField.type)" />
            </template>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import S3FileGenerator from "@/views/customer/components/S3FileGenerator";
import { mapActions } from "vuex"
import { mask, TheMask } from "vue-the-mask";
import { Money } from "v-money";

export default {
  components: {
    DatePicker,
    TheMask,
    Money,
    VueGoogleAutocomplete,
    S3FileGenerator
  },
  directives: {
    mask
  },

  props: ["customFields", "productFields", "prId", "objectId"],

  data() {
    return{
      localCustomData: [],
      localProductData: [],
      productAddressFieldArray: [],
      moneyMask: {
        thousands: ",",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      moneyMaskNumber: {
        decimal: "",
        thousands: "",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false,
      },
      productAddressField: {
        rawAddress: "",
        index: "",
        suburb: "",
        state: "",
        unit_number: "",
        street_number: "",
        street_name: "",
        street_type: "",
        postcode: ""
      },
      states: [
        { text: "NSW", value: "NSW" },
        { text: "ACT", value: "ACT" },
        { text: "QLD", value: "QLD" },
        { text: "VIC", value: "VIC" },
        { text: "NT", value: "NT" },
        { text: "SA", value: "SA" },
        { text: "WA", value: "WA" },
        { text: "TAS", value: "TAS" },
      ],
    }
  },
  methods: {
    ...mapActions("paymentRequest", ["saveProductFieldInPr"]),

    moment(date) {
      return moment(date).format("DD-MMM-YYYY");
    },

    formatAddress (val) {
      if (!val) {
        return val;
      }

      let obj = JSON.parse(val);
      let address = "";

      if (obj.unit_number && Object.keys(obj.unit_number).length > 0) {
        address = obj.unit_number + ", ";
      }

      if (obj.street_name && Object.keys(obj.street_name).length > 0) {
        address = address + obj.street_name + ", ";
      } else if (obj.street && Object.keys(obj.street).length > 0) {
        address = address + obj.street + ", ";
      }

      if (obj.suburb && Object.keys(obj.suburb).length > 0) {
        address = address + obj.suburb + " ";
      }

      if (obj.state && Object.keys(obj.state).length > 0) {
        address = address + obj.state;
        if (Object.keys(obj.postcode).length > 0) {
          address = address + " " + obj.postcode + ", ";
        } else {
          address = address + ",";
        }
      }

      if (obj.country && Object.keys(obj.country).length > 0) {
        address = address + obj.country;
      } else {
        address = address + "Australia";
      }

      return address;
    },

    showProductField (pf) {
      return pf.displayToCustomerAfter;
    },

    openProductEditMode(key, value) {
      for (let i= 0; i< this.localProductData.length; i++) {
        if (i== key) {
          this.localProductData[i].isOpen = !this.localProductData[i].isOpen;
        } else {
          this.localProductData[i].isOpen = false;
        }
      }
      this.localProductData[key].oldValue = value;
    },

    changeAddressInputMode(key, value) {
      this.localProductData[key].addressManual = value;
    },

    cancelProductEditMode(key) {
      this.localProductData[key].isOpen = false;
      this.localProductData[key].value = this.localProductData[key].oldValue ;
      this.localProductData[key].error = false;
    },

    updateCurrImg(input, key) {
      this.localProductData[key].file = input.target.files[0];
      this.localProductData[key].value = input.target.files[0].name;
    },

    uploadImgInput(key) {
      this.$refs[`uploadForImgInput${key}`][0].click();
    },

    addZeroes(num) {
      num = String(num);
      return Number(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    },

    async saveProductField(key) {
      const saveData = this.localProductData[key];
      saveData.paymentRequestId = this.prId;
      saveData.objectId = this.objectId;

      if (saveData.type === "address") {
        saveData.value = JSON.stringify(this.productAddressFieldArray[saveData._id]);
      }

      const fd = new FormData();

      fd.append("file", saveData.file);
      fd.append("form", JSON.stringify(saveData));

      const obj = {
        data: fd,
        config: {
          header: {
            "Content-Type": "multipart/form-data",
          },
        },
      };

      this.$vs.loading();

      await this.saveProductFieldInPr(obj).then((response) => {
        const resultData = response.data.data;
        this.getLocalProductData(resultData);
        this.$vs.loading.close();
      }).catch((ex) => {
        this.showToastMessage(ex.response.data.title, ex.response.data.message, "error");
        this.$vs.loading.close();
      });
    },

    getAddressForProductField: function (addressData, placeResultData, _id) {
      this.addressData = addressData;
      this.productAddressFieldArray[_id].unit_number = "";

      if (this.addressData.street_number) {
        this.productAddressFieldArray[_id].street_number =
          this.addressData.street_number;
      }

      if (this.addressData.postal_code) {
        this.productAddressFieldArray[_id].postcode =
          this.addressData.postal_code;
      }

      if (placeResultData.vicinity) {
        this.productAddressFieldArray[_id].suburb = placeResultData.vicinity;
      }

      if (this.addressData.administrative_area_level_1) {
        this.productAddressFieldArray[_id].state =
          this.addressData.administrative_area_level_1;
      }

      if (addressData.route) {
        let street_address = "";

        for (const address of placeResultData.address_components) {
          if (address.types.includes("street_number"))
            street_address += address.short_name + " ";

          if (address.types.includes("route"))
            street_address += address.long_name;
        }

        this.productAddressFieldArray[_id].street_name = street_address;
      }
    },

    getLocalProductData(productFields) {
      this.localProductData = productFields.map((item) => ({
        ...item,
        isOpen: false,
        oldValue: "",
        file: "",
        addressManual: false,
        required: true
      })).filter((customField) =>  customField.displayToCustomerAfter || customField.editableByCustomerAfter);

      this.localProductData.forEach((item) => {
        if (item.type == "currency") {
          item.value = item.value.replace("$ ", "");
          item.value = item.value.replace(",", "");
          item.value = this.addZeroes(item.value);
        }
      });
    },

    deleteFile(key) {
      this.localProductData[key].value = "";
      this.localProductData[key].file = "";
    },

    handleDateValidation(key, value) {
      let error = false;
      let errorMessage = "";
      const showDatePickerValid = this.handleDatePickerFormat(value);

      if (!value) {
        error = true;
        errorMessage = "Date is required";
      } else if (!showDatePickerValid) {
        error = true;
        errorMessage = "Invalid date";
      }

      this.localProductData[key].error = error;
      this.localProductData[key].errorMessage = errorMessage;
    },

    handleNumericValue(e) {
      if (e.target.type === "number" && !e.key.match(/^[0-9]+$/)) {
        e.preventDefault();
      }
    },
  },

  created() {
    this.getLocalProductData(this.productFields);
    for (let i = 0; i < this.localProductData.length; i++) {
      if (this.localProductData[i].type === "address") {
        this.productAddressField = JSON.parse(this.localProductData[i].value);
        this.productAddressField.rawAddress = `${this.productAddressField.street_name},${this.productAddressField.suburb} ${this.productAddressField.state}, Australia`;
        this.productAddressFieldArray[this.localProductData[i]._id] = this.productAddressField;
      }
    }
  }
};
</script>

